import './social-link.css';
import blueskyImage from './images/bluesky.png';
import discordImage from './images/discord.png';
import emailImage from './images/email.png';
import githubImage from './images/github.png';
import instagramImage from './images/instagram.png';
import kofiImage from './images/kofi.png';
import merchImage from './images/merch.png';
import tiktokImage from './images/tiktok.png';
import twitchImage from './images/twitch.png';
import twitterImage from './images/twitter.png';
import youtubeImage from './images/youtube.png';
import websiteImage from './images/website.png';

const typeToUrlMap = {
    bluesky: blueskyImage,
    discord: discordImage,
    email: emailImage,
    github: githubImage,
    instagram: instagramImage,
    kofi: kofiImage,
    merch: merchImage,
    tiktok: tiktokImage,
    twitch: twitchImage,
    twitter: twitterImage,
    youtube: youtubeImage,
};

export const SocialLink = ({link}) => {
    const {type, url, name} = link;
    const typeUrl = typeToUrlMap[type] || websiteImage;
    return (
        <a className='social-link' href={url}>
            <img src={typeUrl} alt={`${type} logo`} />
            <span>{name}</span>
        </a>
    );
};